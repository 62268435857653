<template>
  <div v-if="getMqttIsInstanceConnected">
    <!-- pageStyle Begin -->
    <div :style="pageStyle">
      <Popper
        class="labelStyle"
        style="position: relative; width: 100%"
        placement="bottom"
        arrow
        offsetDistance="0"
      >
        <template #content>
          <div>
            Upgrading software ensures access to the latest features, improved
            performance, and enhanced security. Stay current to maximize
            productivity and safeguard information. Pressing the "Start" button
            will initiate the process of downloading the new software version
            directly from the cloud server.
          </div>
        </template>
        <div class="label" style="width: 100%">
          <i class="bx bx-info-circle" />
          <input type="text" class="textlabel" value="Upgrade" readonly />
        </div>
      </Popper>
      <p style="margin: 1vh 2vw 1vh 2vw; text-align: center">
        <b
          >Current Version {{ getRemoteAccessUpgradeStatusOpMode }}.{{
            getRemoteAccessUpgradeStatusMajor
          }}.{{ getRemoteAccessUpgradeStatusMinor }}</b
        >
      </p>
      <div>
        <button id="button" class="button" @:click="handleUpgradeClick">
          {{ getRemoteAccessUpgradeStatusMessage }}
        </button>
      </div>
      <div v-if="getRemoteAccessInfoUpgradeInstallFlag">
        <p
          style="
            margin: 1vh 2vw 1vh 2vw;
            text-align: center;
            color: lightskyblue;
          "
        >
          <b
            >Install Version {{ getRemoteAccessUpgradeStatusUpgradeOpMode }}.{{
              getRemoteAccessUpgradeStatusUpgradeMajor
            }}.{{ getRemoteAccessUpgradeStatusUpgradeMinor }}
          </b>
        </p>
      </div>
      <div v-else>
        <div v-if="getRemoteAccessInfoUpgradeFlag">
          <p
            style="
              margin: 1vh 2vw 1vh 2vw;
              text-align: center;
              color: lightskyblue;
            "
          >
            <b
              >Download Version
              {{ getRemoteAccessUpgradeStatusUpgradeOpMode }}.{{
                getRemoteAccessUpgradeStatusUpgradeMajor
              }}.{{ getRemoteAccessUpgradeStatusUpgradeMinor }}
            </b>
          </p>
          <CircleProgress ref="indicator" />
        </div>
      </div>
    </div>
    <!-- pageStyle End -->
  </div>
  <div v-else :style="pageStyle">
    <div class="conwaiter">Waiting for MQTT connection...</div>
  </div>
</template>
<script>
import AppConstants from "@/AppConstants.js";
import CircleProgress from "@/components/CircleProgress.vue";
import Popper from "vue3-popper";
import { mapGetters, mapMutations } from "vuex";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
let size = AppConstants.get2Size();
/* -- */
export default {
  data() {
    return {
      pageStyle: {
        position: "absolute",
        background: "black",
        border: "1px solid black",
        left: MENU_WIDTH + "px",
        top: "0vh",
        width: size.width + "vw",
        height: "100vh",
        textAlign: "left",
        color: "white",
      },
    };
  },
  components: {
    Popper,
    CircleProgress,
  },
  computed: {
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getMqttUserTopic: "mqttStore/getMqttUserTopic",
      getMqttIsInstanceConnected: "mqttStore/getMqttIsInstanceConnected",
      getRemoteAccessUpgradeStatusPercentage:
        "mqttStore/getRemoteAccessUpgradeStatusPercentage",
      getRemoteAccessUpgradeStatusOpMode:
        "mqttStore/getRemoteAccessUpgradeStatusOpMode",
      getRemoteAccessUpgradeStatusMajor:
        "mqttStore/getRemoteAccessUpgradeStatusMajor",
      getRemoteAccessUpgradeStatusMinor:
        "mqttStore/getRemoteAccessUpgradeStatusMinor",
      getRemoteAccessUpgradeStatusUpgradeOpMode:
        "mqttStore/getRemoteAccessUpgradeStatusUpgradeOpMode",
      getRemoteAccessUpgradeStatusUpgradeMajor:
        "mqttStore/getRemoteAccessUpgradeStatusUpgradeMajor",
      getRemoteAccessUpgradeStatusUpgradeMinor:
        "mqttStore/getRemoteAccessUpgradeStatusUpgradeMinor",
      getRemoteAccessUpgradeStatusCode:
        "mqttStore/getRemoteAccessUpgradeStatusCode",
      getRemoteAccessUpgradeStatusMessage:
        "mqttStore/getRemoteAccessUpgradeStatusMessage",
      getRemoteAccessInfoFirstUpdateFlag:
        "mqttStore/getRemoteAccessInfoFirstUpdateFlag",
      getRemoteAccessInfoUpgradeFlag:
        "mqttStore/getRemoteAccessInfoUpgradeFlag",
      getRemoteAccessInfoUpgradeInstallFlag:
        "mqttStore/getRemoteAccessInfoUpgradeInstallFlag",
    }),
  },
  methods: {
    ...mapMutations({
      setRemoteAccessTimeout: "mqttStore/setRemoteAccessTimeout",
    }),
    handleUpgradeClick: function () {
      if (this.getMqttIsInstanceConnected) {
        let mode = "start";
        if (this.getRemoteAccessInfoUpgradeFlag) {
          mode = "stop";
        }
        /* -- */
        let message = "{";
        message += "'mode':'" + mode + "'";
        message += "}";
        this.mqttInstance.publish(
          this.getMqttUserTopic + "/remoteAccess/upgradeRequest",
          message,
          0,
          {},
          () => {}
        );
      }
    },
    updateInfo: function () {
      if (this.getMqttIsInstanceConnected) {
        let message = "{";
        message += "'name':'upgrade'";
        message += "}";
        this.mqttInstance.publish(
          this.getMqttUserTopic + "/update",
          message,
          0,
          {},
          () => {}
        );
      }
    },
  },
  mounted() {},
  created() {
    this.updateInfo();
  },
  watch: {
    getRemoteAccessInfoFirstUpdateFlag: function () {
      this.updateInfo();
    },
    getRemoteAccessUpgradeStatusPercentage: function () {
      if (this.getRemoteAccessInfoUpgradeFlag) {
        this.$refs.indicator.set(this.getRemoteAccessUpgradeStatusPercentage);
      }
    },
  },
};
</script>
<style scoped>
.input {
  color: white;
  background-color: black;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  width: 100%;
  border: 3px solid #303030;
  padding: 10px;
  border-radius: 25px;
}
.input:hover {
  border: 3px solid white;
}
.input:focus {
  border: 3px solid white;
  outline: none;
}
.button {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  width: 100%;
  margin: 1vh 0vw 1vh 0vw;
  padding: 10px;
  border-radius: 25px;
}
.button:hover {
  border: 3px solid white;
}
.separator {
  height: 1px;
  background-color: white;
  margin: 10px 0;
}
.labelStyle {
  position: relative;
  text-align: left;
  float: left;
  --popper-theme-background-color: white;
  --popper-theme-background-color-hover: white;
  --popper-theme-text-color: black;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 25px;
  --popper-theme-padding: 10px;
}
.label {
  position: relative;
  color: gray;
  background-color: black;
  caret-color: transparent;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  border: 3px solid #303030;
  padding: 5px;
  border-radius: 25px;
  text-align: center;
}
.label:hover {
  border: 3px solid white;
}
.label:focus {
  border: 3px solid white;
  outline: none;
}
.conwaiter {
  color: white;
  font-size: x-large;
  margin: auto;
  width: 100%;
  border: 3px solid gray;
  padding: 10px;
  text-align: center;
}
.textlabel {
  color: gray;
  background-color: black;
  font-size: large;
  margin: 0vh 1vw 0vh 1vw;
  border: none;
  outline: none;
  text-align: center;
}
</style>
