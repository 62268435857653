<template>
  <div :style="infoStyle">
    <p style="margin: 1vh 2vw 1vh 2vw; text-align: center">
      <b>Smart Light Controller</b>
    </p>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          <b> System Information </b> refers to details about the device.
          <div class="separator"></div>
          <b>Serial Number</b><br />{{ getSerialNumber }}<br />
          <b>Registration</b><br />{{ getRegistration }} <br /><b>Security</b
          ><br />{{ getSecurity }} <br /><b>Security Code</b><br />{{
            getSecurityCode
          }}
          <br /><b>Firmware Type</b><br />{{ getFirmwareType }}

          <br />
          <b>Local URL</b><br /><a v-bind:href="getLocalUrl">{{
            getLocalUrl
          }}</a>

          <br /><b>Global URL</b><br /><a v-bind:href="getGlobalUrl">{{
            getGlobalUrl
          }}</a>
        </div>
      </template>
      <div class="label" style="width: 100%">
        <i class="bx bx-info-circle" />
        <input type="text" class="textlabel" value="System" readonly />
      </div>
    </Popper>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          <b>Dashboard</b> buttons allow to activate different scenarios
          providing a variety of lighting options. To activate a specific
          scenario, simply press the corresponding button. The system will
          adjust its brightness and color temperature based on the predefined
          settings.
        </div>
      </template>
      <div class="label" style="width: 100%">
        <i class="bx bx-info-circle" />
        <input type="text" class="textlabel" value="Dashboard" readonly />
      </div>
    </Popper>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          <b>Configuration</b> menu option defines the desired player.
          <li>
            <b>Generic Player</b> provides the basic functionality to turn the
            light on and off.
          </li>
          <li>
            <b>Sequence player</b> enables advanced control by playing frame
            sequences to create light shows accompanied with music.
          </li>
          <li>
            <b>DDP (Dynamic Display Protocol) Player</b> facilitates the
            creation and playback of light shows designed with a PC sequencer.
          </li>
        </div>
      </template>
      <div class="label" style="width: 100%">
        <i class="bx bx-info-circle" />
        <input type="text" class="textlabel" value="Configuration" readonly />
      </div>
    </Popper>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          <b>Setting</b> menu option allows to customize and assign preferred
          colors and brightness to each scenario. <br />Navigate and select the
          desired color adjusting RGB values from 0 up to 255 and color
          temperature controls. Once the necessary color is selected, click any
          scenario button or light strip to apply the chosen color.
          <br />
          <b>Get</b> button allows to obtain color temperature of a selected
          component.
          <br />
          <b>Set</b> button allows to assign color temperature to a selected
          component.
          <br />
          <b>Cor</b> button applies the color temperature correction.
          <br />
          <b>Raw</b> button applies the raw colors without any correction.
          <br />
          <b>View</b> button applies the current scenario settings.
          <br />
          <b>Save</b> button saves the current settings to retentive memory. The
          selected scenario will be activated automatically on the next POWER ON
          event.
        </div>
      </template>
      <div class="label" style="width: 100%">
        <i class="bx bx-info-circle" />
        <input type="text" class="textlabel" value="Settings" readonly />
      </div>
    </Popper>
    <Popper
      class="labelStyle"
      style="position: relative; width: 100%"
      placement="bottom"
      arrow
      offsetDistance="0"
    >
      <template #content>
        <div>
          There are few applications based on Time-of-Flight (ToF) Sensor.
          <li>
            <b>Motion Detector</b> detects movement and triggers a predefined
            action, such as turning on lights.
          </li>
          <li>
            <b>Zone Detector</b> measures the minimum distance to an object or
            person allowing for zone-specific actions.
          </li>
          <li>
            <b>ToF Sensor</b> application provides detailed information of raw
            distance data to objects.
          </li>
        </div>
      </template>
      <div class="label" style="width: 100%">
        <i class="bx bx-info-circle" />
        <input type="text" class="textlabel" value="Applications" readonly />
      </div>
    </Popper>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AppConstants from "@/AppConstants.js";
import Popper from "vue3-popper";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
let size = AppConstants.get2Size();
export default {
  data() {
    return {
      infoStyle: {
        position: "absolute",
        background: "black",
        border: "1px solid black",
        left: MENU_WIDTH + "px",
        top: "0vh",
        width: size.width + "vw",
        height: "100vh",
        textAlign: "left",
        color: "white",
      },
    };
  },
  components: {
    Popper,
  },
  computed: {
    ...mapGetters({
      getSerialNumber: "infoMsgStore/getSerialNumber",
      getRegistration: "infoMsgStore/getRegistration",
      getSecurity: "infoMsgStore/getSecurity",
      getSecurityCode: "infoMsgStore/getSecurityCode",
      getFirmwareType: "infoMsgStore/getFirmwareType",
      getLocalUrl: "infoMsgStore/getLocalUrl",
      getGlobalUrl: "infoMsgStore/getGlobalUrl",
    }),
  },
};
</script>
<style scoped>
.labelStyle {
  position: relative;
  text-align: left;
  float: left;
  --popper-theme-background-color: white;
  --popper-theme-background-color-hover: white;
  --popper-theme-text-color: black;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 25px;
  --popper-theme-padding: 10px;
}
.label {
  position: relative;
  color: gray;
  background-color: black;
  caret-color: transparent;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  border: 3px solid #303030;
  padding: 5px;
  border-radius: 25px;
  text-align: center;
}
.label:hover {
  border: 3px solid white;
}
.label:focus {
  border: 3px solid white;
  outline: none;
}
.textlabel {
  color: gray;
  background-color: black;
  font-size: large;
  margin: 0vh 1vw 0vh 1vw;
  border: none;
  outline: none;
  text-align: center;
}
.separator {
  height: 1px;
  background-color: black;
  margin: 2px 0;
}
/* unvisited/visited link */
a:link,
a:visited {
  color: green;
  text-decoration: none;
  font-size: large;
}
/* mouse over link */
a:hover {
  color: green;
  text-decoration: underline;
  font-size: large;
}
/* selected link */
a:active {
  color: blue;
  text-decoration: none;
  font-size: large;
}
</style>
