import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Info from "../views/Info.vue";
import Setting from "../views/Setting.vue";
import Configuration from "../views/Configuration.vue";
import WiFi from "../views/WiFi.vue";
import LiDAR from "../views/Lidar.vue";
import Upgrade from "../views/Upgrade.vue";
const routes = [
  {
    path: process.env.VUE_APP_PATH,
    name: "Home",
    component: Home,
  },
  {
    path: process.env.VUE_APP_PATH + "Info",
    name: "Info",
    component: Info,
  },
  {
    path: process.env.VUE_APP_PATH + "Configuration",
    name: "Configuration",
    component: Configuration,
  },
  {
    path: process.env.VUE_APP_PATH + "Setting",
    name: "Setting",
    component: Setting,
  },
  {
    path: process.env.VUE_APP_PATH + "WiFi",
    name: "WiFi",
    component: WiFi,
  },
  {
    path: process.env.VUE_APP_PATH + "LiDAR",
    name: "Applications",
    component: LiDAR,
  },
  {
    path: process.env.VUE_APP_PATH + "Upgrade",
    name: "Upgrade",
    component: Upgrade,
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
