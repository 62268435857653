<template>
  <div v-if="getMqttIsInstanceConnected">
    <div v-if="0 == getConfigMode">
      <div v-if="isReady" :style="lidarStyle">
        <Popper
          class="labelStyle"
          style="position: relative; width: 100%"
          arrow
          offsetDistance="0"
        >
          <template #content>
            <div>
              Smart Light Controller supports 3 applications:
              <li>Motion Detector</li>
              <li>Zone Detector</li>
              <li>ToF Sensor</li>
              Motion Detector detects movement and triggers a predefined action,
              such as turning on lights. <br />Zone Detector measures the
              minimum distance to an object or person allowing for zone-specific
              actions.
              <br />
              ToF Sensor provides raw distance data to objects. <br />
              <hr />
              Please select the desired application from the drop-down menu
              below.
            </div>
          </template>
          <div class="label" style="width: 100%">
            <i class="bx bx-info-circle" />
            <input type="text" class="textlabel" value="Application" readonly />
          </div>
        </Popper>
        <div>
          <select
            class="selector"
            v-model="selected"
            @change="onChange($event)"
          >
            <option v-for="index in getApps" :value="index.value" :key="index">
              {{ index.text }}
            </option>
          </select>
        </div>
        <!-- NOT SELECTED  BEGIN -->
        <div v-if="0 == getMode">
          <button
            id="button"
            class="button"
            v-if="apply"
            @:click="handleApplyClick"
          >
            Apply
          </button>
          <!-- NOT SELECTED  END -->
        </div>
        <!-- ZONE DETECTOR BEGIN -->
        <div v-else-if="2 == getMode">
          <Popper
            class="labelStyle"
            style="position: relative; width: 100%"
            arrow
            offsetDistance="0"
          >
            <template #content>
              <div>
                The measurement range is divided into 8 distinct zones. Each
                zone is associated with a predetermined action. When an object
                or person is detected within a particular zone, the associated
                action is triggered.
              </div>
            </template>
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                value="Zone Number"
                readonly
              />
            </div>
          </Popper>
          <div>
            <select
              class="selector"
              v-model="selectedZone"
              @change="onZoneChange($event)"
            >
              <option value="0">Zone #1</option>
              <option value="1">Zone #2</option>
              <option value="2">Zone #3</option>
              <option value="3">Zone #4</option>
              <option value="4">Zone #5</option>
              <option value="5">Zone #6</option>
              <option value="6">Zone #7</option>
              <option value="7">Zone #8</option>
            </select>
          </div>
          <Popper
            class="labelStyle"
            style="position: relative; width: 100%"
            arrow
            offsetDistance="0"
          >
            <template #content>
              <div>
                Activation in the context of a zone detector refers to the event
                that triggers a specific action when desired distance is
                detected.
              </div>
            </template>
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                value="Activation"
                readonly
              />
            </div>
          </Popper>
          <div>
            <select
              class="selector"
              v-model="selectedZoneActivation"
              @change="onActivationChange($event)"
            >
              <option value="0">Disabled</option>
              <option value="1">Scenario #1</option>
              <option value="2">Scenario #2</option>
              <option value="3">Scenario #3</option>
              <option value="4">Scenario #4</option>
              <option value="5">Scenario #5</option>
              <option value="6">Scenario #6</option>
              <option value="7">Scenario #7</option>
              <option value="8">Scenario #8</option>
              <option value="9">Scenario #9 (OFF)</option>
            </select>
          </div>
          <Popper
            class="labelStyle"
            style="position: relative; width: 100%"
            arrow
            offsetDistance="0"
          >
            <template #content>
              <div>
                Deactivation based on a timer refers to a feature that
                automatically turns on the predefined action after a certain
                period of inactivity. When the zone detector is activated, the
                timer starts counting down. If no further event is detected
                within the specified time interval, the deactivation predefined
                action will be triggered.
              </div>
            </template>
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                value="DeActivation"
                readonly
              />
            </div>
          </Popper>
          <div>
            <select
              class="selector"
              v-model="selectedZoneDeActivation"
              @change="onDeActivationChange($event)"
            >
              <option value="0">Disabled</option>
              <option value="1">Scenario #1</option>
              <option value="2">Scenario #2</option>
              <option value="3">Scenario #3</option>
              <option value="4">Scenario #4</option>
              <option value="5">Scenario #5</option>
              <option value="6">Scenario #6</option>
              <option value="7">Scenario #7</option>
              <option value="8">Scenario #8</option>
              <option value="9">Scenario #9 (OFF)</option>
            </select>
          </div>
          <Popper
            class="labelStyle"
            style="position: relative; width: 100%"
            arrow
            offsetDistance="0"
          >
            <template #content>
              <div>
                "Entry Sustained Trigger" signifies that the trigger event
                happens upon an object or person entering the zone and remaining
                there, prompting the system to engage the deactivation timer.
                "Exit Timeout Trigger" encapsulates the concept of timing out or
                counting down after an object or person leaves a zone.
              </div>
            </template>
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                value="DeActivation Timer Event"
                readonly
              />
            </div>
          </Popper>
          <div>
            <select
              class="selector"
              v-model="selectedZoneTimerTrigger"
              @change="onZoneTimerTriggerChange($event)"
            >
              <option value="0">Disabled</option>
              <option value="1">Entry Sustained Trigger</option>
              <option value="2">Exit Timeout Trigger</option>
            </select>
          </div>
          <div :style="blockStyle">
            <Popper
              class="labelStyle"
              style="position: relative; width: 70%"
              placement="top"
              arrow
              offsetDistance="0"
            >
              <template #content>
                <div>
                  Maximum distance for detecting can be from 30mm to 4000mm
                </div>
              </template>
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Min Distance"
                  readonly
                />
              </div>
            </Popper>
            <div class="inputStyle" style="width: 30%">
              <input
                type="text"
                class="input"
                style="width: 100%"
                :style="{ color: minColor }"
                :value="getZoneMin"
                @input="minChange"
              />
            </div>
          </div>
          <div :style="blockStyle">
            <Popper
              class="labelStyle"
              style="position: relative; width: 70%"
              placement="top"
              arrow
              offsetDistance="0"
            >
              <template #content>
                <div>
                  Maximum distance for detecting can be from 30mm to 4000mm.
                </div>
              </template>
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Max Distance"
                  readonly
                />
              </div>
            </Popper>
            <div class="inputStyle" style="width: 30%">
              <input
                type="text"
                class="input"
                style="width: 100%"
                :style="{ color: maxColor }"
                :value="getZoneMax"
                @input="maxChange"
              />
            </div>
          </div>
          <div :style="blockStyle">
            <Popper
              class="labelStyle"
              style="position: relative; width: 70%"
              placement="top"
              arrow
              offsetDistance="0"
            >
              <template #content>
                <div>
                  The timer is set in seconds and determines the duration of
                  inactivity required for the system to trigger the deactivation
                  after the last event.
                </div>
              </template>
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="DeAct Timer"
                  readonly
                />
              </div>
            </Popper>
            <div class="inputStyle" style="width: 30%">
              <input
                type="text"
                class="input"
                style="width: 100%"
                :style="{ color: timerColor }"
                :value="getZoneTimer"
                @input="timerChange"
              />
            </div>
          </div>
          <button
            id="button"
            class="button"
            v-if="apply"
            @:click="handleApplyClick"
          >
            Apply
          </button>
        </div>
        <!-- ZONE DETECTOR END -->
        <!-- MOTION DETECTOR BEGIN -->
        <div v-else-if="1 == getMode">
          <Popper
            offsetDistance="0"
            arrow
            class="labelStyle"
            style="width: 100%"
            content="Activation in the context of a motion detector refers to the event that triggers a specific action  when motion is detected."
          >
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                value="Activation"
                readonly
              />
            </div>
          </Popper>
          <div>
            <select
              class="selector"
              v-model="selectedActivation"
              @change="onActivationChange($event)"
            >
              <option value="0">Disabled</option>
              <option value="1">Scenario #1</option>
              <option value="2">Scenario #2</option>
              <option value="3">Scenario #3</option>
              <option value="4">Scenario #4</option>
              <option value="5">Scenario #5</option>
              <option value="6">Scenario #6</option>
              <option value="7">Scenario #7</option>
              <option value="8">Scenario #8</option>
              <option value="9">Scenario #9 (OFF)</option>
            </select>
          </div>
          <Popper
            offsetDistance="0"
            arrow
            class="labelStyle"
            style="width: 100%"
            content="Deactivation based on a timer refers to a feature that automatically turns on the predefined action after a certain period of inactivity.
        When the motion detector is activated by detecting movement, the timer starts counting down. 
        If no further motion is detected within the specified time interval, the deactivation predefined action will be triggered."
          >
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                value="DeActivation"
                readonly
              />
            </div>
          </Popper>
          <div>
            <select
              class="selector"
              v-model="selectedDeActivation"
              @change="onDeActivationChange($event)"
            >
              <option value="0">Disabled</option>
              <option value="1">Scenario #1</option>
              <option value="2">Scenario #2</option>
              <option value="3">Scenario #3</option>
              <option value="4">Scenario #4</option>
              <option value="5">Scenario #5</option>
              <option value="6">Scenario #6</option>
              <option value="7">Scenario #7</option>
              <option value="8">Scenario #8</option>
              <option value="9">Scenario #9 (OFF)</option>
            </select>
          </div>
          <Popper
            offsetDistance="0"
            arrow
            class="labelStyle"
            style="width: 100%"
            content="Sensitivity of a motion detector refers to its ability to detect and respond to motion within its detection range.
        Finding the right sensitivity level is essential to avoid false alarms caused by non-relevant movements 
        while ensuring the detector effectively responds to meaningful motion events."
          >
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                value="Sensitivity"
                readonly
              />
            </div>
          </Popper>
          <div>
            <select
              class="selector"
              v-model="selectedSensitivity"
              @change="onSensitivityChange($event)"
            >
              <option value="1">Low</option>
              <option value="2">Medium</option>
              <option value="3">High</option>
            </select>
          </div>
          <div :style="blockStyle">
            <Popper
              offsetDistance="0"
              placement="top"
              arrow
              class="labelStyle"
              style="width: 70%"
              content="Minimum distance for detecting motion can be from 400mm to 4000mm.
           The difference between the maximum and minimum distances cannot be greater than 1500 mm."
            >
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Min Distance"
                  readonly
                />
              </div>
            </Popper>
            <div class="inputStyle" style="width: 30%">
              <input
                type="text"
                class="input"
                style="width: 100%"
                :style="{ color: minColor }"
                :value="getMin"
                @input="minChange"
              />
            </div>
          </div>
          <div :style="blockStyle">
            <Popper
              offsetDistance="0"
              placement="top"
              arrow
              class="labelStyle"
              style="width: 70%"
              content="Maximum distance for detecting motion can be from 400mm to 4000mm.
           The difference between the maximum and minimum distances cannot be greater than 1500 mm."
            >
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Max Distance"
                  readonly
                />
              </div>
            </Popper>
            <div class="inputStyle" style="width: 30%">
              <input
                type="text"
                class="input"
                style="width: 100%"
                :style="{ color: maxColor }"
                :value="getMax"
                @input="maxChange"
              />
            </div>
          </div>
          <div :style="blockStyle">
            <Popper
              offsetDistance="0"
              placement="top"
              arrow
              class="labelStyle"
              style="width: 70%"
              content="The timer is set in seconds and determines the duration of inactivity required for the system 
          to trigger the deactivation after the last motion event."
            >
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="DeAct Timer"
                  readonly
                />
              </div>
            </Popper>
            <div class="inputStyle" style="width: 30%">
              <input
                type="text"
                class="input"
                style="width: 100%"
                :style="{ color: timerColor }"
                :value="getTimer"
                @input="timerChange"
              />
            </div>
          </div>
          <button
            id="button"
            class="button"
            v-if="apply"
            @:click="handleApplyClick"
          >
            Apply
          </button>
        </div>
        <!-- MOTION DETECTOR END -->
        <!-- RAW SENSOR BEGIN -->
        <div v-else-if="3 == getMode">
          <div v-if="getTofSensorMode">
            <Popper
              offsetDistance="0"
              arrow
              class="labelStyle"
              style="width: 100%"
              content="ToF (Time of Flight) sensor, provides raw distance data (in mm) to objects. 
        The sensor works by emitting infrared light towards an object 
        and measuring the time it takes for the light to bounce back, 
        thus calculating the distance based on the speed of light."
            >
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Distance"
                  readonly
                />
              </div>
            </Popper>
            <SensorPanelComponent :width="sensorWidth" />
            <Popper
              offsetDistance="0"
              arrow
              class="labelStyle"
              style="width: 100%"
              content="The sensitivity of a Time of Flight (ToF) sensor refers to its ability to detect subtle changes in distance within its field of view. 
        This sensitivity is crucial for applications where precise distance measurements is required. 
        This parameter adjusts the sensitivity of a Zone detector for ensuring accurate and reliable detection."
            >
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Sensitivity"
                  readonly
                />
              </div>
            </Popper>
            <div>
              <select
                class="selector"
                v-model="selectedSensorSensitivity"
                @change="onSensorSensitivityChange($event)"
              >
                <option value="1">Low</option>
                <option value="2">Medium</option>
                <option value="3">High</option>
              </select>
            </div>
            <button
              id="button"
              class="button"
              v-if="apply"
              @:click="handleApplyClick"
            >
              Apply
            </button>
          </div>
          <div v-else>
            <div class="conwaiter">
              ToF sensor is only available for <br />Local Version of WEB GUI
            </div>
          </div>
        </div>
        <!-- RAW SENSOR END -->
      </div>
      <div v-else :style="lidarStyle">
        <div class="labelStyle" style="width: 100%">
          <div v-if="0 != getStatus">
            <Popper
              style="position: relative; width: 100%"
              arrow
              offsetDistance="0"
            >
              <template #content>
                <div>
                  Time-Of-Flight multizone ranging sensor is not detected
                </div>
              </template>
              <input
                type="text"
                class="label"
                style="width: 100%"
                value="No Sensor"
                readonly
              />
            </Popper>
          </div>
          <div v-else class="conwaiter">
            {{ statusMessage }}
          </div>
        </div>
      </div>
    </div>
    <div v-else :style="lidarStyle">
      <div class="labelStyle" style="width: 100%">
        <div class="conwaiter">Disabled</div>
      </div>
    </div>
  </div>
  <div v-else :style="lidarStyle">
    <div class="labelStyle" style="width: 100%">
      <div class="conwaiter">Waiting for MQTT connection...</div>
    </div>
  </div>
</template>
<script>
import AppConstants from "@/AppConstants.js";
import { mapGetters, mapMutations } from "vuex";
import Popper from "vue3-popper";
import SensorPanelComponent from "@/components/SensorPanel.vue";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
let size = AppConstants.get2Size();
export default {
  data() {
    return {
      lidarStyle: {
        position: "absolute",
        background: "black",
        border: "1px solid black",
        left: MENU_WIDTH + "px",
        top: "0vh",
        width: size.width + "vw",
        height: "100vh",
        color: "white",
      },
      blockStyle: {
        position: "relative",
        background: "black",
        border: "1px solid black",
        color: "white",
        display: "inline-block",
        width: size.width + "vw",
      },
      selected: 0,
      selectedActivation: 0,
      selectedDeActivation: 0,
      selectedSensitivity: 1,
      selectedSensorSensitivity: 1,
      apply: false,
      minColor: "lightgrey",
      maxColor: "lightgrey",
      timerColor: "lightgrey",
      isMinValid: 1,
      isMaxValid: 1,
      isTimerValid: 1,
      statusMessage: "Loading...",
      /* Zone Detector */
      selectedZone: 1,
      selectedZoneActivation: 0,
      selectedZoneDeActivation: 0,
      selectedZoneTimerTrigger: 0,
      /* Raw Sensor */
      sensorWidth: size.width,
    };
  },
  components: {
    Popper,
    SensorPanelComponent,
  },
  computed: {
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getMqttUserTopic: "mqttStore/getMqttUserTopic",
      getMqttIsInstanceConnected: "mqttStore/getMqttIsInstanceConnected",
      getMode: "lidarStore/getMode",
      getSavedMode: "lidarStore/getSavedMode",
      getStatus: "lidarStore/getStatus",
      getApps: "lidarStore/getApps",
      getMin: "lidarStore/getMin",
      getMax: "lidarStore/getMax",
      getTimer: "lidarStore/getTimer",
      getActivation: "lidarStore/getActivation",
      getDeActivation: "lidarStore/getDeActivation",
      getSensitivity: "lidarStore/getSensitivity",
      getSensorSensitivity: "lidarStore/getSensorSensitivity",
      isReady: "lidarStore/isReady",
      getUpdateCounter: "lidarStore/getUpdateCounter",
      /* Zone Detector */
      getZone: "lidarStore/getZone",
      getZoneMin: "lidarStore/getZoneMin",
      getZoneMax: "lidarStore/getZoneMax",
      getZoneTimer: "lidarStore/getZoneTimer",
      getZoneTimerTrigger: "lidarStore/getZoneTimerTrigger",
      getZoneActivation: "lidarStore/getZoneActivation",
      getZoneDeActivation: "lidarStore/getZoneDeActivation",
      getZoneMinArray: "lidarStore/getZoneMinArray",
      getZoneMaxArray: "lidarStore/getZoneMaxArray",
      getZoneTimerArray: "lidarStore/getZoneTimerArray",
      getZoneTimerTriggerArray: "lidarStore/getZoneTimerTriggerArray",
      getZoneActivationArray: "lidarStore/getZoneActivationArray",
      getZoneDeActivationArray: "lidarStore/getZoneDeActivationArray",
      /* Raw Sensor */
      getSensorUpdateCounter: "lidarStore/getSensorUpdateCounter",
      getSensorArray: "lidarStore/getSensorArray",
      getTofSensorMode: "lidarStore/getTofSensorMode",
      getConfigMode: "configStore/getMode",
    }),
  },
  methods: {
    ...mapMutations({
      setMode: "lidarStore/setMode",
      setMin: "lidarStore/setMin",
      setMax: "lidarStore/setMax",
      setTimer: "lidarStore/setTimer",
      setReady: "lidarStore/setReady",
      setActivation: "lidarStore/setActivation",
      setDeActivation: "lidarStore/setDeActivation",
      setSensitivity: "lidarStore/setSensitivity",
      setSensorSensitivity: "lidarStore/setSensorSensitivity",
      /* Zone Detector */
      setZone: "lidarStore/setZone",
      setZoneMin: "lidarStore/setZoneMin",
      setZoneMax: "lidarStore/setZoneMax",
      setZoneTimer: "lidarStore/setZoneTimer",
      setZoneTimerTrigger: "lidarStore/setZoneTimerTrigger",
      setZoneActivation: "lidarStore/setZoneActivation",
      setZoneDeActivation: "lidarStore/setZoneDeActivation",
    }),
    checkParameters: function () {
      /* Check all flags */
      let minThreshold;
      let maxThreshold;
      let value;
      let parameter;
      let apply = true;
      /* MIN DISTANCE */
      if (1 == this.selected) {
        parameter = this.getMin;
        minThreshold = 400;
        maxThreshold = 4000;
      }
      if (2 == this.selected) {
        parameter = this.getZoneMin;
        minThreshold = 30;
        maxThreshold = 4000;
      }
      if (isNaN(parameter) || 0 == parameter.length) {
        this.minColor = "red";
        apply = false;
        this.isMinValid = 0;
      } else {
        value = parseInt(parameter);
        if (value < minThreshold || value > maxThreshold) {
          this.minColor = "red";
          this.apply = false;
          this.isMinValid = 0;
        } else {
          this.minColor = "lightgrey";
          this.isMinValid = 1;
        }
      }
      /* MAX DISTANCE */
      if (1 == this.selected) {
        parameter = this.getMax;
        minThreshold = 400;
        maxThreshold = 4000;
      }
      if (2 == this.selected) {
        parameter = this.getZoneMax;
        minThreshold = 30;
        maxThreshold = 4000;
      }
      if (isNaN(parameter) || 0 == parameter.length) {
        this.maxColor = "red";
        apply = false;
        this.isMaxValid = 0;
      } else {
        value = parseInt(parameter);
        if (value < minThreshold || value > maxThreshold) {
          this.maxColor = "red";
          apply = false;
          this.isMaxValid = 0;
        } else {
          this.maxColor = "lightgrey";
          this.isMaxValid = 1;
        }
      }
      /* TIMER */
      if (1 == this.selected) {
        parameter = this.getTimer;
        minThreshold = 1;
        maxThreshold = 99999;
      }
      if (2 == this.selected) {
        parameter = this.getZoneTimer;
        minThreshold = 1;
        maxThreshold = 99999;
      }
      if (isNaN(parameter) || 0 == parameter.length) {
        this.timerColor = "red";
        apply = false;
        this.isTimerValid = 0;
      } else {
        value = parseInt(parameter);
        if (value < minThreshold || value > maxThreshold) {
          this.timerColor = "red";
          apply = false;
          this.isTimerValid = 0;
        } else {
          this.timerColor = "lightgrey";
          this.isTimerValid = 1;
        }
      }
      /* -- */
      if (this.isMaxValid && this.isMinValid && this.isTimerValid) {
        /* Check range */
        if (1 == this.selected) {
          let min = parseInt(this.getMin);
          let max = parseInt(this.getMax);
          let diff = max - min;
          if (1500 < diff || 0 >= diff) {
            apply = false;
          }
        }
      } else {
        apply = false;
      }
      this.apply = apply;
      return this.apply;
    },
    handleApplyClick: function () {
      let message;
      this.setReady(false);
      this.apply = false;
      this.statusMessage = "Applying...";
      if (1 == this.selected) {
        message = "{";
        message += "'name':'lidar'";
        message += ",'mode':'setmotion'";
        message += ",'min':" + parseInt(this.getMin);
        message += ",'max':" + parseInt(this.getMax);
        message += ",'timer':" + parseInt(this.getTimer);
        message += ",'activation':" + parseInt(this.getActivation);
        message += ",'deactivation':" + parseInt(this.getDeActivation);
        message += ",'sensitivity':" + parseInt(this.getSensitivity);
        message += "}";
      } else if (2 == this.selected) {
        message = "{";
        message += "'name':'lidar'";
        message += ",'mode':'setzone'";
        message += ",'zone':" + (1 + parseInt(this.getZone));
        message += ",'minArray':[" + this.getZoneMinArray + "]";
        message += ",'maxArray':[" + this.getZoneMaxArray + "]";
        message += ",'timerArray':[" + this.getZoneTimerArray + "]";
        message += ",'activationArray':[" + this.getZoneActivationArray + "]";
        message +=
          ",'deactivationArray':[" + this.getZoneDeActivationArray + "]";
        message +=
          ",'timerTriggerArray':[" + this.getZoneTimerTriggerArray + "]";
        message += "}";
      } else if (3 == this.selected) {
        message = "{";
        message += "'name':'lidar'";
        message += ",'mode':'setraw'";
        message +=
          ",'sensorSensitivity':" + parseInt(this.getSensorSensitivity);
        message += ",'sensorArray':[" + this.getSensorArray + "]";
        message += "}";
      } else {
        message = "{";
        message += "'name':'lidar'";
        message += ",'mode':'notselected'";
        message += "}";
      }
      this.mqttInstance.publish(
        this.getMqttUserTopic + "/update",
        message,
        0,
        {},
        () => {}
      );
    },
    getInfoReady: function () {
      let ready = this.isReady;
      if (!ready) {
        this.selected = this.getMode;
      }
      return ready;
    },
    onChange: function (event) {
      this.selected = event.target.value;
      this.setMode(this.selected);
      this.checkParameters();
      if (0 == this.selected) {
        /* Enable Apply  for Not Selected option */
        this.apply = true;
      } else {
        if (this.getSavedMode != this.getMode) {
          this.apply = true;
        } else {
          this.apply = false;
        }
      }
    },
    onZoneChange: function (event) {
      this.selectedZone = event.target.value;
      this.setZone(this.selectedZone);
      this.selectedZoneActivation = this.getZoneActivation;
      this.selectedZoneDeActivation = this.getZoneDeActivation;
      this.selectedZoneTimerTrigger = this.getZoneTimerTrigger;
      this.checkParameters();
    },
    onZoneTimerTriggerChange: function (event) {
      this.selectedZoneTimerTrigger = event.target.value;
      this.setZoneTimerTrigger(this.selectedZoneTimerTrigger);
      this.checkParameters();
    },
    onActivationChange: function (event) {
      this.selectedActivation = event.target.value;
      if (1 == this.selected) {
        this.setActivation(this.selectedActivation);
        this.checkParameters();
      }
      if (2 == this.selected) {
        this.setZoneActivation(this.selectedActivation);
        this.checkParameters();
      }
    },
    onDeActivationChange: function (event) {
      this.selectedDeActivation = event.target.value;
      if (1 == this.selected) {
        this.setDeActivation(this.selectedDeActivation);
        this.checkParameters();
      }
      if (2 == this.selected) {
        this.setZoneDeActivation(this.selectedDeActivation);
        this.checkParameters();
      }
    },
    onSensitivityChange: function (event) {
      this.selectedSensitivity = event.target.value;
      this.setSensitivity(this.selectedSensitivity);
      this.checkParameters();
    },
    onSensorSensitivityChange: function (event) {
      this.selectedSensorSensitivity = event.target.value;
      this.setSensorSensitivity(this.selectedSensorSensitivity);
      this.apply = true;
    },
    minChange: function (event) {
      if (1 == this.selected) {
        this.setMin(event.target.value);
      }
      if (2 == this.selected) {
        this.setZoneMin(event.target.value);
      }
      this.checkParameters();
    },
    maxChange: function (event) {
      if (1 == this.selected) {
        this.setMax(event.target.value);
      }
      if (2 == this.selected) {
        this.setZoneMax(event.target.value);
      }
      this.checkParameters();
    },
    timerChange: function (event) {
      if (1 == this.selected) {
        this.setTimer(event.target.value);
      }
      if (2 == this.selected) {
        this.setZoneTimer(event.target.value);
      }
      this.checkParameters();
    },
  },
  created() {
    let message = "{";
    message += "'name':'lidar'";
    message += "}";
    if (this.getMqttIsInstanceConnected) {
      this.mqttInstance.publish(
        this.getMqttUserTopic + "/update",
        message,
        0,
        {},
        () => {}
      );
    }
  },
  mounted() {},
  watch: {
    getUpdateCounter: function () {
      this.selected = this.getMode;
      this.selectedActivation = this.getActivation;
      this.selectedDeActivation = this.getDeActivation;
      this.selectedSensitivity = this.getSensitivity;
      this.selectedSensorSensitivity = this.getSensorSensitivity;
      this.selectedZone = this.getZone;
      this.selectedZoneActivation = this.getZoneActivation;
      this.selectedZoneDeActivation = this.getZoneDeActivation;
      this.selectedZoneTimerTrigger = this.getZoneTimerTrigger;
      this.checkParameters();
      if (0 == this.getStatus) {
        this.setReady(true);
      } else {
        this.setReady(false);
      }
      if (this.selected) {
        this.apply = false;
      } else {
        this.apply = true;
      }
    },
    getSensorUpdateCounter: function () {
      if (3 == this.getMode) {
        this.apply = true;
      } else {
        this.apply = false;
      }
    },
  },
};
</script>
<style scoped>
.input {
  color: white;
  background-color: black;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  border: 3px solid #303030;
  padding: 1px;
  border-radius: 25px;
  text-align: center;
}
.input:hover {
  border: 3px solid white;
}
.input:focus {
  border: 3px solid white;
  outline: none;
}
.label {
  position: relative;
  color: gray;
  background-color: black;
  caret-color: transparent;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  border: 3px solid #303030;
  padding: 1px;
  border-radius: 25px;
  text-align: center;
}
.label:hover {
  border: 3px solid white;
}
.label:focus {
  border: 3px solid white;
  outline: none;
}
.button {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  width: 100%;
  margin: 1vh 0vw 1vh 0vw;
  padding: 10px;
  border-radius: 25px;
}
.button:hover {
  border: 3px solid white;
}
.selector {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  margin: 1vh 0vw 1vh 0vw;
  padding: 1px;
  border-radius: 25px;
  width: 100%;
}
.selector:focus {
  border: 3px solid #303030;
  outline: none;
}
.selector:hover {
  border: 3px solid white;
}
.labelStyle {
  position: relative;
  text-align: left;
  float: left;
  --popper-theme-background-color: white;
  --popper-theme-background-color-hover: white;
  --popper-theme-text-color: black;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 25px;
  --popper-theme-padding: 10px;
}
.inputStyle {
  position: relative;
  background: black;
  border: 1px solid black;
  color: white;
  text-align: right;
  float: right;
}
.conwaiter {
  color: white;
  font-size: x-large;
  margin: auto;
  width: 100%;
  border: 3px solid gray;
  padding: 10px;
  text-align: center;
}
.textlabel {
  color: gray;
  background-color: black;
  font-size: large;
  margin: 0vh 1vw 0vh 1vw;
  border: none;
  outline: none;
  text-align: center;
}
</style>
