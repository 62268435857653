<template>
  <div style="background-color: #000">
    <div class="wrapper">
      <div class="progress-bar" data-percent="0">
        <div class="progress">
          <div class="progress-fill"></div>
        </div>
        <div class="percents">
          <div class="percents-wrapper">
            <span>%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "CircleProgress",
  components: {},
  data: function () {
    return {
      isAnimating: false,
      pb: null,
      percent: 0,
      deg: 0,
    };
  },
  mounted() {
    (this.pb = $(".progress-bar")),
      (this.percent = parseInt(this.pb.data("percent"))),
      (this.deg = (360 * this.percent) / 100);
    this.isAnimating = false;
    $(".progress-bar").fadeIn();
    if (this.percent > 50) {
      this.pb.addClass("next-part");
    }
    $(".progress-fill").css("transform", "rotate(" + this.deg + "deg)");
    $(".percents span").html(this.percent + "%");
  },
  computed: {},
  methods: {
    update(newPercent) {
      if (!this.isAnimating) {
        this.isAnimating = true;
        var interval = setInterval(
          function () {
            this.percent += 1;
            this.deg += 3.6;
            if (this.percent > 50) {
              this.pb.addClass("next-part");
            }
            $(".progress-fill").css("transform", "rotate(" + this.deg + "deg)");
            $(".percents span").html(this.percent + "%");
            if (this.percent >= newPercent) {
              clearInterval(interval);
              this.isAnimating = false;
            }
          }.bind(this),
          30
        );
      }
    },
    set(newPercent) {
      this.pb.removeClass("next-part");
      this.percent = newPercent;
      this.deg = (360 * this.percent) / 100;
      this.isAnimating = false;
      $(".progress-bar").fadeIn();
      if (this.percent > 50) {
        this.pb.addClass("next-part");
      }
      $(".progress-fill").css("transform", "rotate(" + this.deg + "deg)");
      $(".percents span").html(this.percent + "%");
    },
  },
};
</script>
<style scoped>
.wrapper {
  margin: 50px auto 0px;
  position: relative;
  height: 150px;
}

.progress-bar {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  margin: 50px auto 0;
}

.progress {
  max-height: 650px;
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  width: 200px;
  height: 200px;
  clip: rect(0, 200px, 200px, 100px);
  background-color: white;
}

.progress-bar.next-part {
  background-color: lightskyblue;
}

.next-part .progress {
  clip: rect(0, 100px, 200px, 0);
}
.progress .progress-fill {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  width: 200px;
  height: 200px;
  clip: rect(0, 100px, 200px, 0);
  background: lightskyblue;
  transform: rotate(60deg);
}
.percents {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 173.9130434783px / 2);
  top: calc(50% - 173.9130434783px / 2);
  width: 173.9130434783px;
  height: 173.9130434783px;
  background: black;
  text-align: center;
  display: table;
}

.percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.percents span {
  display: block;
  font-size: 2.6em;
  /* font-weight: bold; */
  color: lightskyblue;
}

button {
  display: block;
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  background-color: #ffffff;
  color: black;
  border: none;
  outline: none;
  top: 110px;
  border-radius: 5px;
  padding: 10px;
  margin: 0px auto 150px;
  cursor: pointer;
}

button:active {
  transform: scale(0.9);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
</style>
